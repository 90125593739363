<template lang="pug">
  article.page._min-h-screen.tw-borders
    template(v-if="doc")
      //- body
      ._min-h-screen.lg__flex.lg__flex-row.lg__bg-gray-100

        //- header
        //- - portrait: sticky header with dropdown menu
        //- - lg: sticky left panel
        header._z-20._left-0.lg__static._w-full.lg__w-1x4(:class="{'_sticky': scrollMenu.length}", :style="{top: headerHt + 'px'}")
          //- sticky body
          //- - top: set based on header ht
          .lg__sticky._w-full._left-0._px-6.lg__p-7._h-auto._bg-gray-100._transform.lg__transform-none._transition._duration-100(:style="{top: headerHt + 'px'}", :class="{'_-translate-y-18': breadcrumbTucked}")
            //- header row
            ._h-18._flex._justify-between._items-center.lg__h-auto(ref="header")
              //- title
              heading-arrowed(:arrow="true")
                h4._inline {{ $prismic.asText(doc.data.title) }}
              //- h1._h-18._flex._items-center.lg__h-auto.lg__items-start
                icon._mr-4._mt-px._transform._scale-90._origin-center(name="arrow-down-right-black")
                span._text-20.lg__text-21._truncate.lg__whitespace-normal._block._flex-1 {{ $prismic.asText(doc.data.title) }}

              //- (scroll menu btn)
              button.lg__hidden._p-6._-m-6(v-if="scrollMenu.length", @click="mblMenu = !mblMenu", aria-label="Toggle Page Menu")
                img._transform(src="~@/assets/icons/2021/Dropdown-Arrow.svg", :class="{'_rotate-180': mblMenu}", alt="dropdown icon")

            //- scroll menu
            ul._absolute._left-0.lg__static._px-6._w-full._list-none.lg__block._text-15._py-5.lg__px-0.lg__pt-12._bg-gray-100._overflow-y-scroll.scrollbars-hidden(v-if="scrollMenu.length", :class="{'_hidden': !mblMenu}", @click="mblMenu = false", style="max-height: calc(100vh - 4.5rem * 5)")
              //- scroll points...
              li(v-for="(item, i) in scrollMenu", :class="{'_pl-6': item.indent}")
                a(:href="'#' + item.id", @click.prevent="onScrollLink(item.id)", :class="{'_border-b _border-current': i === activeScrollPoint}") {{ item.text }}

        //- body (.flex so heights/keylines extend)
        section._relative._flex-1._flex._bg-white
          slice-body._flex-1(:slices="doc.data.body", :blurActive="mblMenu")

          //- (blur overlay mobile)
          button._absolute._overlay._z-5._block.lg__hidden(:class="{'_hidden': !mblMenu}", style="backdrop-filter:blur(8px); background:rgba(0,0,0,0.15)", @click="mblMenu = false", aria-label="Close Page Menu")

      //- header(ref="header")
        nav.nav.subnav._items-center
          a.subnav__back-btn(@click="onBackBtn", aria-label="Back")
          template(v-if="$route.name === 'page-not-found'")
            span Page Not Found
          template(v-else-if="doc")
            //- a.subnav__back-btn(@click="$router.go(-1)", aria-label="Back")
            template(v-if="doc.data.header_format === 'sub-page links'")
              a(v-for="(section, index) in sections", :key="index", @click="$router.replace({params: {sectionId: section.primary.slug}})", :class="{'router-link-exact-active': section.primary.slug === sectionId}") {{section.primary.title[0].text}}
            template(v-else)
              | {{ text(doc, 'title') }}

      //- body
      //- .legal-page__body.is-overlay-off(ref="scrollBody")
        transition(name="legalpagebody")
          div(v-if="sections")
            section(ref="section", v-for="(section, index) in sections", :id="section.primary.slug")
              //- legal (three cols)
              template(v-if="section.slice_type === 'section'")
                .slice--section.slice--section--columned.text-left.underline-links
                  prismic-rich-text.textbody(:field="section.primary.text")
              //- accordion section
              template(v-else-if="section.slice_type === 'accordion'")
                slice-accordion(:slice="section", :index="index")
              //-
              img(v-if="index === sections.length -1", src, @error="startAtSection").is-hidden
            //- footer.is-hidden-tablet(v-if="about")
              footerr(:doc="about", v-on="$listeners", @expanded="scrollToFooter")
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import _throttle from 'lodash/throttle'
import SliceAccordion from '@/components/Page/Page__Slice--Accordion'
import SliceBody from '@/components/SliceBody'
import HeadingArrowed from '@/components/HeadingArrowed'
import Icon from '@/components/Icon'
import kb from 'lodash/kebabCase'
let lastRt
let pts
let hdr
export default {
  name: 'Page',
  props: ['uid', 'sectionId'], // , 'about'],
  components: { SliceAccordion, SliceBody, Icon, HeadingArrowed },
  data () {
    return {
      doc: null,
      activeScrollPoint: -1,
      text: Vue.$text,
      // html: Vue.$html,
      pageScroll: () => {},
      headerHt: 0,
      mblMenu: false,
      scrollMenu: []
    }
  },
  computed: {
    ...mapState(['about', 'breadcrumbTucked'])
  },
  watch: {
    '$route' () {
      // route changed in same view
      this.startAtSection()
    },
    uid () {
      // page url changed
      this.doc = null
      this.getPage()
    },
    doc (doc) {
      // doc changed
      // this.$refs.scrollBody.scrollTo(0, 0)
      this.updateMeta()
    }
    // activeScrollPoint (index) {
    //   if (this.sections) this.$router.replace({ params: { sectionId: this.sections[index].primary.slug } })
    // }
  },
  methods: {
    scrollPageTo: _throttle(function (slug) {
      const scrollBody = '#legal-page .legal-page__body'
      const el = this.$el.querySelector('#' + slug)
      this.pageScroll() // cancel last
      if (el) {
        this.pageScroll = this.$scrollTo('#' + slug, 1000, {
          container: scrollBody,
          offset: this.$refs.header.offsetTop * -2 + 1
        })
      }
    }, 500),
    async getPage () {
      this.doc = await this.$store.dispatch('getPage', this.uid)
      if (!this.doc) {
        return this.$router.replace({ name: 'page-not-found' })
      }
      this.onDoc()
    },
    scrollToFooter () {
      this.$refs.scrollBody.scrollTo(0, 10000)
    },

    onScroll: _throttle(function () {
      this.setActiveScrollPoint()
    }, 200),

    setActiveScrollPoint () {
      // get index of current section based on scroll position
      // const sections = this.$refs.section
      // if (sections) {
      //   const offset = this.$refs.header.offsetHeight * 2 // offset based on the nav height ?
      //   let index = 0
      //   for (var i = 0; i < sections.length; i++) {
      //     index = yPos >= sections[i].offsetTop - offset ? i : index
      //   }
      //   return index
      // }
      if (this.scrollMenu.length) {
        this.setHeaderHt()
        pts = Array.from(document.querySelectorAll('.scroll-point'))
        // ...from end of els, find first section that's top is above window
        for (var i = pts.length - 1; i >= 0; i--) {
          if (pts[i].getBoundingClientRect().top <= window.innerHeight * 0.5) {
            this.activeScrollPoint = i
            break
          }
        }
      }
    },

    startAtSection () {
      // load view at sub-section
      if (this.sectionId) {
        this.scrollPageTo(this.sectionId)
      }
    },

    updateMeta () {
      Vue.$meta.setTitle(Vue.$text(this.doc, 'title', false))
      Vue.$meta.setDescription(Vue.$text(this.doc, 'meta_description', false))
    },

    onBackBtn () {
      if (lastRt) return this.$router.go(-1)
      this.$router.push('/')
    },

    scrollLinkId (slice) {
      return '#' + kb(this.$prismic.asText(slice.primary.item_title))
    },
    onScrollLink (id) {
      setTimeout(() => {
        if (id) {
          const isLg = window.innerWidth >= 1024
          let offset = 0
          if (isLg) {
            this.setHeaderHt()
            offset = this.headerHt * -1 + 1
          } else {
            offset = this.$refs.header.getBoundingClientRect().bottom * -1 + 1
          }
          this.$root.$emit('beforeScrollLink')
          // scroll!
          const el = document.getElementById(id)
          this.$scrollTo(el, {
            offset,
            onCancel: () => setTimeout(() => this.$root.$emit('afterScrollLink'), 100),
            onDone: async () => {
              // check if properly scrolled to section since lazy-loading...
              await this.$nextTick()
              const elTop = el.getBoundingClientRect().top
              if (Math.abs(Math.ceil(elTop)) - Math.abs(offset) > 5) {
                // try again!
                return this.onScrollLink(id)
              }
              // ...otherwise end
              setTimeout(() => this.$root.$emit('afterScrollLink'), 100)
            }
          })
        }
      }, 10)
    },

    setHeaderHt () {
      hdr = hdr || document.getElementById('app-header')
      if (this.headerHt !== hdr.offsetHeight) {
        this.headerHt = hdr.offsetHeight
      }
      // const ticker = hdr.querySelector('#app-header__ticker')
      // const rowHt = hdr.querySelector('.app-header__top-row').offsetHeight
      // this.headerHt = ticker ? rowHt * 3 : rowHt * 2
    },
    setScrollMenu () {
      document.querySelectorAll('.scroll-point').forEach(el => {
        this.scrollMenu.push({ id: el.id, text: el.innerText, indent: el.getAttribute('data-indent')?.split('_').pop() })
      })
    },

    onDoc () {
      this.$store.commit('setBreadcrumb', []) // [this.$prismic.asText(this.doc.data.title), { ...this.$route }]])
      setTimeout(() => {
        this.setHeaderHt()
        this.setScrollMenu()
        this.setActiveScrollPoint()
      }, 300)
    }
  },
  beforeRouteEnter (to, from, next) {
    lastRt = from && from.name
    next()
  },
  created () {
    this.getPage()
  },
  mounted () {
    // this.$el.querySelector('.legal-page__body').addEventListener('scroll', this.onScroll)
    this.setHeaderHt()
    this.$root.$on('tickerDestroyed', this.setHeaderHt)
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll)
    // this.$el.querySelector('.legal-page__body').removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
@import '../style/variables';
#app .legal-page{
  // height:100%;
  // overflow:hidden;
  background:$white;
  // z-index: $z-overlay;
  > header{
    position: fixed;
    top:calc(#{$nav-h} - 1px); left:0;
    width:100%;
    z-index:10;
    .subnav{
      position: static;
      top:auto;
    }
  }
  .legal-page__body{
    // height:100vh;
    // overflow-y:scroll;
    padding-top:$nav-h*2;
    section{
      // min-height:calc(100vh - #{$nav-h} * 2 + 1px);
      // border-bottom-style: solid;
      display:flex;
      // align-items: center;
      padding-bottom:$nav-h;
    }
  }
  .slice--section{
    // padding:$pad*2 $pad;
    padding:$pad;
    line-height: 1.2;
    max-width:60.5em;
    margin:0 auto;
    &.slice--section--columned{
      columns:3;
      column-gap:3em;
      @media (max-width:480px) {
        columns:auto;
      }
    }
  }
  .router-link-exact-active{
    color:$secondary;
  }

  @include mobile () {
    > header {
      top:$nav-h--mobile;
    }
    .slice--section{
      padding:$pad;
      min-height:calc(100vh - #{$nav-h--mobile} * 2);
    }
    .legal-page__body {
      padding-top: $nav-h--mobile*2;
      // &:before{
        // content:'';
        // display:block;
        // height:$nav-h--mobile*2;
      // }
      > footer{
        // border-top-style: solid;
        // border-bottom-style: solid;
      }
    }
  }
}
</style>

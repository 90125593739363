<template lang="pug">
  .page__slice--accordion
    header
      prismic-rich-text.textbody(:field="slice.primary.intro")
    article(v-for="(item, i) in slice.items")
      dropdown
        prismic-rich-text(slot="summary", :field="item.item_title")
        prismic-rich-text.textbody.text-justify(:field="item.item_body")
</template>

<script>
import Dropdown from '@/components/Dropdown'
export default {
  name: 'Page__Slice--Accordion',
  props: ['slice', 'index'],
  components: { Dropdown }
}
</script>

<style lang="scss">
@import '../../style/variables';
.page__slice--accordion{
  padding:$pad*2 $pad;
  line-height: 1.2;
  max-width:60.5em;
  width:100%;
  margin:0 auto;
  > header{
    margin-bottom: 2em;
  }
  article .dropdown header{
    margin-top: -1px;
    border-top-style:solid;
    border-bottom-style:solid;
    padding:0.88em;
    cursor:pointer;
  }
  article .textbody{
    padding-top:2em;
    padding-bottom:2em;
  }
}
</style>
